import React from "react"
import * as util from "../utils"
import * as CONST from "../constants"
import * as template from "./templates"
import { display } from "../helpers/widgetHelper"


/**
 * 
 * @returns length of array to populate number of already stored fields in edit mode for Set
 */
export function getSetMinItems() {
  return 0
}

/**
 * 
 * @returns length of array to populate number of already stored fields in edit mode for List
 */
 export function getListMinItems() {
  return 1
}





