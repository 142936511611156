// DATA PRODUCTS
export const LEGO = "Lego"

//modes 
export const CREATE="Create"
export const EDIT="Edit"
export const VIEW="View"
export const MODE_HELP_TEXT="Select the mode in which FrameViewer should be displayed in"


